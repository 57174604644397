/**
 * Fertighaus.de Web Tracking
 *
 * It's called _wt instead of something more readable to
 * a) Make it shorter, as we'll make use of this function extensively
 * b) Avoid being blocked by tracking blocker scripts
 */

import 'whatwg-fetch'

import { SADB } from '@js/common/SADB'
import {
  env,
  getUUIDv4,
  isHouseRelatedType,
  isVendorRelatedType,
  supportsLocalStorage
} from '@js/common/utils'

function isCookieExisting(cookieName) {
  return document.cookie.indexOf(`${cookieName}=`) !== -1
}

function getCookieValue(cookieName) {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}

const planoutIdCookieName = 'planout_id'
const distinctIdCookieName = 'nr_distinct_id'
const isProd = DEPLOY_ENV === 'production'
const ga4UserCentricId = '87JYasXPF'

function sendTrackingEvent(trackingPayload) {
  let event_hub_url = env('EVENT_HUB_INPUT_SOURCE')
  if (!event_hub_url) {
    return Promise.reject('Event HUB Url missing')
  }

  const base64data = trackingPayload
  const isBauenSite = /bauen/.test(window.__DOMAIN__)
  // send AVIV GTM data
  if (
    isBauenSite &&
    window.UC_UI?.getServicesBaseInfo().find(
      data => data.id === ga4UserCentricId
    ).consent.status // GA4
  ) {
    const eventData = JSON.parse(window.atob(base64data))
    if (eventData.category === 'page' && eventData.action === 'display') {
      let av_pageview_event = {
        event: 'page_view',
        av_url_path: window.location.pathname,
        av_platform: 'web',
        av_site: 'BUN',
        av_pagettitle: document.title,
        av_category: eventData.label,
        av_user: {
          cid: '2071035240.1710770466'
        }
      }
      if (!isProd) {
        console.log(
          '%cGTM:',
          'background: #55aa55; color: #fff; font-weight: bold; padding: 2px 3px; border-radius: 2px;',
          av_pageview_event
        )
      }
      window.dataLayer.push(av_pageview_event)
    }
  }
  if (Object.getPrototypeOf(navigator).hasOwnProperty('sendBeacon')) {
    const headers = {
      type: 'application/json'
    }
    const body = { data: base64data }
    const blob = new Blob([JSON.stringify(body)], headers)
    try {
      const addedToQueue = navigator.sendBeacon(event_hub_url, blob)
      if (addedToQueue === false) {
        throw new Error('Failed to sendBeacon, trying fetch...')
      }
      return Promise.resolve('OK!')
    } catch (err) {
      try {
        return fetch(event_hub_url, {
          method: 'POST',
          body: JSON.stringify({
            data: base64data
          })
        })
      } catch (error) {
        return Promise.reject('Failed to send event')
      }
    }
  } else {
    return fetch(event_hub_url, {
      method: 'POST',
      body: JSON.stringify({
        data: base64data
      })
    })
  }
}

class TrackingQueue {
  constructor(initialEvents) {
    this.trackingEvents = initialEvents || []
  }

  enqueue(element) {
    const updatedTrackingEvents = [element, ...this.trackingEvents]
    sessionStorage.setItem('wtEvents', JSON.stringify(updatedTrackingEvents))
    this.trackingEvents = updatedTrackingEvents
  }

  appendCookieInfo(trackinEventEncodedValue) {
    const trackingEventObject = JSON.parse(
      window.atob(trackinEventEncodedValue)
    )
    const blockedCookiesMessage = 'user-has-cookies-blocked'
    if (isCookieExisting(distinctIdCookieName)) {
      trackingEventObject[distinctIdCookieName] =
        trackingEventObject[distinctIdCookieName] === blockedCookiesMessage
          ? getCookieValue(distinctIdCookieName) || blockedCookiesMessage
          : trackingEventObject[distinctIdCookieName]
    }

    if (isCookieExisting(planoutIdCookieName)) {
      trackingEventObject[planoutIdCookieName] =
        trackingEventObject[planoutIdCookieName] === blockedCookiesMessage
          ? getCookieValue(planoutIdCookieName) || blockedCookiesMessage
          : trackingEventObject[planoutIdCookieName]
    }
    return window.btoa(JSON.stringify(trackingEventObject))
  }

  async unloadQueue() {
    if (this.trackingEvents.length > 0) {
      const sendPromises = this.trackingEvents.map(event =>
        sendTrackingEvent(this.appendCookieInfo(event))
      )
      const results = await Promise.allSettled(sendPromises)

      // Filter out successfully sent events and update the queue with failed ones
      const failedEvents = results.filter(
        result => result.status === 'rejected'
      )

      // Update the queue and sessionStorage with the failed events
      this.trackingEvents = failedEvents
      if (failedEvents.length > 0) {
        sessionStorage.setItem('wtEvents', JSON.stringify(failedEvents))
      } else {
        sessionStorage.removeItem('wtEvents')
      }

      return results
    }
    return []
  }

  get getQueue() {
    return this.trackingEvents
  }

  get length() {
    return this.trackingEvents.length > 0
  }

  get isEmpty() {
    return this.length === 0
  }
}

const initialTrackingEvents = supportsLocalStorage()
  ? JSON.parse(sessionStorage.getItem('wtEvents'))
  : []
window['_trackingQueue'] = new TrackingQueue(initialTrackingEvents)

void (function () {
  /* Checks if AB Testing data is in localStorage */
  const checkIfWeHaveABTestingData = () =>
    new Promise(done => {
      let intervalCheck = null

      const checkLocalStorage = () => {
        if (localStorage.getItem('ab-testing')) {
          try {
            const abTestingData = JSON.parse(localStorage.getItem('ab-testing'))
            if (
              window.SherlockContainer &&
              window.SherlockContainer.environment &&
              window.SherlockContainer.environment.AB_TEST_VERSION &&
              abTestingData.version ===
                window.SherlockContainer.environment.AB_TEST_VERSION
            ) {
              clearInterval(intervalCheck)
              done(abTestingData)
            } else {
              const planoutId = window['🍪'].read('planout_id')
                ? window['🍪'].read('planout_id')
                : 'user-has-cookies-blocked'

              get(`/ab-test/${planoutId}`).then(data => {
                localStorage.setItem('ab-testing', data)
              })
            }
          } catch (e) {}
        }
      }

      if (supportsLocalStorage()) {
        intervalCheck = setInterval(checkLocalStorage, 100)
      } else {
        done({
          ...window['SherlockContainer']['environment'][
            'AB_TEST_EXPERIMENTS'
          ].reduce((finalObject, test) => {
            finalObject[test] = 'A'
            return finalObject
          }, {}),
          version: window['SherlockContainer']['environment']['AB_TEST_VERSION']
        })
      }
    })

  const isEventTestUser = () => {
    return (
      supportsLocalStorage() &&
      /neuraum-selenium-test-wt/.test(window['🍪'].read('nr_distinct_id'))
    )
  }

  /* Tracking function */
  const webTracking = async (params = {}) => {
    const isIMSite = /immowelt|immonet|bauen/.test(window.__DOMAIN__)

    if (!params.category || !params.action) {
      return
    }

    if (!params.local_timestamp) {
      params.local_timestamp = new Date().toISOString()
    }

    const abTesting = await checkIfWeHaveABTestingData()

    try {
      if (abTesting) {
        const { version, ...assignments } = abTesting

        params.ab_testing = {
          assignments: assignments
            ? Object.keys(assignments).map(key => ({
                experiment: key,
                variation: assignments[key]
              }))
            : [],
          version
        }
      }
    } catch (e) {
      params.ab_testing = abTesting || {}
    }

    try {
      const UA = JSON.parse(localStorage.getItem('UA'))
      if (UA && UA['public_id']) {
        params.account_information = params.account_information || {}
        params.account_information.user_seed = UA['public_id']
      }
    } catch (e) {}

    try {
      const pid = localStorage.getItem('wt_pid')
      if (pid) {
        params.account_information = params.account_information || {}
        params.account_information.pid = pid
      }
    } catch (e) {}

    try {
      const SalesAgentData = SADB.getLS()
      if (
        SalesAgentData?.nid &&
        /servicewelt-bauen/.test(window.location.href)
      ) {
        params.account_information = params.account_information || {}
        params.account_information.sales_agent_app = SalesAgentData.nid
      }
    } catch (e) {}

    const metadata_getQueryParam = function (url, param) {
      // Expects a raw URL

      param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
      var regexS = '[\\?&]' + param + '=([^&#]*)',
        regex = new RegExp(regexS),
        results = regex.exec(url)
      if (
        results === null ||
        (results && typeof results[1] !== 'string' && results[1].length)
      ) {
        return ''
      }
      return decodeURIComponent(results[1]).replace(/\+/g, ' ')
    }

    const metadata_includes = function (str, needle) {
      return str.indexOf(needle) !== -1
    }

    const metadata_isString = function (obj) {
      return toString.call(obj) == '[object String]'
    }

    const metadata_strip_empty_properties = function (p) {
      var ret = {}
      for (const k of Object.keys(p)) {
        if (metadata_isString(p[k]) && p[k].length > 0) {
          ret[k] = p[k]
        }
      }
      return ret
    }

    const metadata_info = {
      searchEngine: function (referrer) {
        if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
          return 'google'
        }
        if (referrer.search('https?://(.*)bing.com') === 0) {
          return 'bing'
        }
        if (referrer.search('https?://(.*)yahoo.com') === 0) {
          return 'yahoo'
        }
        if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
          return 'duckduckgo'
        }
        return null
      },

      searchInfo: function (referrer) {
        var search = metadata_info.searchEngine(referrer),
          param = search != 'yahoo' ? 'q' : 'p',
          ret = {}

        if (search !== null) {
          ret['$search_engine'] = search

          var keyword = metadata_getQueryParam(referrer, param)
          if (keyword.length) {
            ret['mp_keyword'] = keyword
          }
        }

        return ret
      },

      /**
       * This function detects which browser is running this script.
       * The order of the checks are important since many user agents
       * include key words used in later checks.
       */
      browser: function (user_agent, vendor, opera) {
        var vendor = vendor || '' // vendor is undefined for at least IE9
        if (opera || metadata_includes(user_agent, ' OPR/')) {
          if (metadata_includes(user_agent, 'Mini')) {
            return 'Opera Mini'
          }
          return 'Opera'
        }
        if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
          return 'BlackBerry'
        }
        if (
          metadata_includes(user_agent, 'IEMobile') ||
          metadata_includes(user_agent, 'WPDesktop')
        ) {
          return 'Internet Explorer Mobile'
        }
        if (metadata_includes(user_agent, 'Edge')) {
          return 'Microsoft Edge'
        }
        if (metadata_includes(user_agent, 'FBIOS')) {
          return 'Facebook Mobile'
        }
        if (metadata_includes(user_agent, 'Chrome')) {
          return 'Chrome'
        }
        if (metadata_includes(user_agent, 'CriOS')) {
          return 'Chrome iOS'
        }
        if (metadata_includes(vendor, 'Apple')) {
          if (metadata_includes(user_agent, 'Mobile')) {
            return 'Mobile Safari'
          }
          return 'Safari'
        }
        if (metadata_includes(user_agent, 'Android')) {
          return 'Android Mobile'
        }
        if (metadata_includes(user_agent, 'Konqueror')) {
          return 'Konqueror'
        }
        if (metadata_includes(user_agent, 'Firefox')) {
          return 'Firefox'
        }
        if (
          metadata_includes(user_agent, 'MSIE') ||
          metadata_includes(user_agent, 'Trident/')
        ) {
          return 'Internet Explorer'
        }
        if (metadata_includes(user_agent, 'Gecko')) {
          return 'Mozilla'
        }
        return ''
      },

      /**
       * This function detects which browser version is running this script,
       * parsing major and minor version (e.g., 42.1). User agent strings from:
       * http://www.useragentstring.com/pages/useragentstring.php
       */
      browserVersion: function (userAgent, vendor, opera) {
        var browser = metadata_info.browser(userAgent, vendor, opera)
        var versionRegexs = {
          'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
          'Microsoft Edge': /Edge\/(\d+(\.\d+)?)/,
          Chrome: /Chrome\/(\d+(\.\d+)?)/,
          'Chrome iOS': /Chrome\/(\d+(\.\d+)?)/,
          Safari: /Version\/(\d+(\.\d+)?)/,
          'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
          Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
          Firefox: /Firefox\/(\d+(\.\d+)?)/,
          Konqueror: /Konqueror:(\d+(\.\d+)?)/,
          BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
          'Android Mobile': /android\s(\d+(\.\d+)?)/,
          'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
          Mozilla: /rv:(\d+(\.\d+)?)/
        }
        var regex = versionRegexs[browser]
        if (regex == undefined) {
          return null
        }
        var matches = userAgent.match(regex)
        if (!matches) {
          return null
        }
        return parseFloat(matches[matches.length - 2])
      },

      os: function () {
        var a = navigator.userAgent
        if (/Windows/i.test(a)) {
          if (/Phone/.test(a) || /WPDesktop/.test(a)) {
            return 'Windows Phone'
          }
          return 'Windows'
        }
        if (/(iPhone|iPad|iPod)/.test(a)) {
          return 'iOS'
        }
        if (/Android/.test(a)) {
          return 'Android'
        }
        if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
          return 'BlackBerry'
        }
        if (/Mac/i.test(a)) {
          return 'Mac OS X'
        }
        if (/Linux/.test(a)) {
          return 'Linux'
        }
        return ''
      },

      device: function (user_agent) {
        if (/Windows Phone/i.test(user_agent) || /WPDesktop/.test(user_agent)) {
          return 'Windows Phone'
        }
        if (/iPad/.test(user_agent)) {
          return 'iPad'
        }
        if (/iPod/.test(user_agent)) {
          return 'iPod Touch'
        }
        if (/iPhone/.test(user_agent)) {
          return 'iPhone'
        }
        if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
          return 'BlackBerry'
        }
        if (/Android/.test(user_agent)) {
          return 'Android'
        }
        return ''
      },

      referringDomain: function (referrer) {
        var split = referrer.split('/')
        if (split.length >= 3) {
          return split[2]
        }
        return ''
      },

      properties: function () {
        return {
          ...metadata_strip_empty_properties({
            $os: metadata_info.os(),
            $browser: metadata_info.browser(
              navigator.userAgent,
              navigator.vendor,
              window.opera
            ),
            $initial_referrer:
              metadata_info.referringDomain(document.referrer) !==
              window.location.host
                ? document.referrer
                : null,
            $initial_referring_domain:
              metadata_info.referringDomain(document.referrer) !==
              window.location.host
                ? metadata_info.referringDomain(document.referrer)
                : null,
            $referrer: document.referrer,
            $referring_domain: metadata_info.referringDomain(document.referrer),
            $device: metadata_info.device(navigator.userAgent),
            ...metadata_info.searchInfo(document.referrer)
          }),
          ...{
            $current_url: window.location.href,
            $browser_version: metadata_info.browserVersion(
              navigator.userAgent,
              navigator.vendor,
              window.opera
            ),
            $screen_height: screen.height,
            $screen_width: screen.width
          }
        }
      }
    }

    let event_uuid = getUUIDv4()
    if (env('EVENT_HUB_INPUT_SOURCE')) {
      let identifiers = {}
      try {
        if (window['🍪'].read('planout_id')) {
          identifiers['planout_id'] = window['🍪'].read('planout_id')
        } else {
          identifiers['planout_id'] = 'user-has-cookies-blocked'
        }

        if (window['🍪'].read('nr_distinct_id')) {
          identifiers['nr_distinct_id'] = window['🍪'].read('nr_distinct_id')
        } else {
          identifiers['nr_distinct_id'] = 'user-has-cookies-blocked'
        }

        identifiers['event_uuid'] = event_uuid
      } catch (e) {}

      const keysToEncode = ['label', 'location', 'values']
      for (const currentKey of keysToEncode) {
        if (currentKey === 'values') {
          params[currentKey] = params[currentKey].map(({ type, value }) => ({
            type: encodeURIComponent(type),
            value: encodeURIComponent(value)
          }))
        } else if (params[currentKey]) {
          params[currentKey] = encodeURIComponent(params[currentKey])
        }
      }

      const base64data = window.btoa(
        JSON.stringify({
          ...params,
          ...metadata_info.properties(),
          ...identifiers
        })
      )

      let event_hub_url = env('EVENT_HUB_INPUT_SOURCE')

      if (!window['🍪'].read('nr_distinct_id') && isIMSite) {
        window['_trackingQueue'].enqueue(base64data)
        return
      }

      if (Object.getPrototypeOf(navigator).hasOwnProperty('sendBeacon')) {
        const headers = {
          type: 'application/json'
        }
        const body = { data: base64data }
        const blob = new Blob([JSON.stringify(body)], headers)
        try {
          navigator.sendBeacon(event_hub_url, blob)
        } catch (err) {
          try {
            fetch(event_hub_url, {
              method: 'POST',
              body: JSON.stringify({
                data: base64data
              })
            })
          } catch (error) {}
        }
      } else {
        fetch(event_hub_url, {
          method: 'POST',
          body: JSON.stringify({
            data: base64data
          })
        })
      }
    }

    let houseConversion = false // test: send generic conversion event to GTM - remove after test is over
    if (params['indicates']) {
      window.dataLayer = window.dataLayer || []
      let prefix = 'TR',
        category = 'WTcategory:' + params['category'],
        action = 'WTaction:' + params['action'],
        label = params['label'] ? 'WTlabel:' + params['label'] : 'WTlabel:',
        location = params['location']
          ? 'WTlocation:' + params['location']
          : 'WTlocation:',
        flow_type = params.flow_information
          ? 'WTflow_type:' + params.flow_information['flow_type']
          : 'WTflow_type:',
        flow_step = params.flow_information
          ? 'WTflow_step:' + params.flow_information['flow_step']
          : 'WTflow_step:'
      params['indicates'].forEach(function (indicator) {
        let indicates = 'WTindicates:' + indicator,
          gtm_trigger = {
            event:
              prefix +
              '_' +
              category +
              '_' +
              action +
              '_' +
              label +
              '_' +
              location +
              '_' +
              flow_type +
              '_' +
              indicates
          }
        if (indicator === 'funnel-step-change') {
          gtm_trigger.event = gtm_trigger.event + '_' + flow_step
        }

        if (indicator === 'user-input') {
          // add a new key to the gtm_trigger object which contains the type and value
          gtm_trigger[params.values[0].type + 'WtValue'] =
            params.values[0].value
        }

        if (indicator === 'house-conversion') {
          // test: send generic conversion event to GTM - remove after test is over
          houseConversion = true
          // Facebook Ads requires a conversion ID for deduplication
          gtm_trigger['conversion_id'] = event_uuid
        }

        if (!isProd) {
          if (isEventTestUser()) {
            localStorage.setItem(
              `gtm!${Date.now()}`,
              JSON.stringify(gtm_trigger)
            )
          }
          console.log(
            '%cGTM:',
            'background: #55aa55; color: #fff; font-weight: bold; padding: 2px 3px; border-radius: 2px;',
            gtm_trigger
          )
        }
        window.dataLayer.push(gtm_trigger)

        if (houseConversion === true) {
          // test: send generic conversion event to GTM - remove after test is over
          window.dataLayer.push({
            event: 'test_house_conversion',
            conversion_id: event_uuid
          })

          // push massivhaus conversion event for add campaigns
          if (window.location.hostname.includes('massivhaus')) {
            let massivhaus_conversion_event = {
              event: 'conversionx',
              send_to: 'AW-1001140993/BVkqCKWgqIMYEIHmsN0D',
              value: 1.0,
              currency: 'EUR'
            }
            window.dataLayer.push(massivhaus_conversion_event)
            if (!isProd) {
              console.log('GTM:', massivhaus_conversion_event)
            }
          }
        }
      })
    }

    if (!isProd) {
      if (isEventTestUser()) {
        localStorage.setItem(
          `wt!${params.category}-${params.action}!${Date.now()}`,
          JSON.stringify({ ...params })
        )
      }
      console.log(
        '%c🙈_wt:',
        'background: #55aaff; color: #fff; font-weight: bold; padding: 2px 3px; border-radius: 2px;',
        params
      )
    }

    if (isIMSite) {
      let utagEvent = null
      let isFunnelViewEvent =
        params.category === 'question' &&
        params.action === 'display' &&
        params.flow_information
      let isModalFunnelViewEvent =
        params.category === 'modal' &&
        params.action === 'display' &&
        params.flow_information
      let pageType = document.body.dataset.pagetype

      if (isFunnelViewEvent || isModalFunnelViewEvent) {
        let step = params.flow_information?.flow_step | 0
        let productFormtype = 'katalog'

        if (isVendorRelatedType(pageType)) {
          productFormtype = 'microsite'
        }
        if (isHouseRelatedType(pageType)) {
          productFormtype = 'hausidee'
        }
        utagEvent = {
          event_category: 'katalog_checkout',
          event_action: 'view',
          event_label: 'katalog_step-' + step.toString().padStart(2, '0'),
          checkout_step: step,
          enh_action: 'checkout',
          page_name: pageType,
          page_section: 'house_building',
          page_category: 'portal_site',
          page_subcategory: 'bestellung',
          product_name: ['kontakt_hausbau'],
          product_formtype: [productFormtype], // adjust for microsite and hausidee
          product_id: ['535'], // ?
          product_quantity: [],
          app_medienid: 'web.bauen'
        }
        if (
          params.values[0].value === 'mtan-request' ||
          params.values[0].value === 'mtan_validation'
        ) {
          utagEvent.event_label = 'mtan_request-step-09'
        }
        if (params.values[0].value === 'mtan-validate') {
          utagEvent.event_label = 'mtan_validate-step-10'
        }
        if (params.values[0].value === 'success') {
          utagEvent.event_label = 'katalog_step-success-catalogue'
        }
        if (params.values[0].value === 'success-house') {
          utagEvent.event_label = 'katalog_step-success-house'
          utagEvent.product_quantity = ['1']
        }
        if (params.values[0].value === 'success-vendor') {
          utagEvent.event_label = 'katalog_step-success-vendor'
          utagEvent.product_quantity = ['1']
        }
      }
      if (
        params.location === 'modal-mtan' &&
        params.action === 'submit' &&
        params.values[0].type === 'mtan'
      ) {
        utagEvent = {
          event_category: 'katalog_checkout',
          event_action: 'view',
          event_label: 'mtan_validate-step-10',
          checkout_step: params.flow_information.flow_step,
          enh_action: 'checkout',
          page_name: pageType,
          page_section: 'house_building',
          page_category: 'portal_site',
          page_subcategory: 'bestellung',
          product_name: ['kontakt_hausbau'],
          product_formtype: ['katalog'],
          product_id: ['535'],
          product_quantity: []
        }
      }
      if (
        params.location === 'question-catalogue-selector' &&
        params.action === 'submit'
      ) {
        utagEvent = {
          event_category: 'katalog_checkout',
          event_action: 'view',
          event_label:
            'katalog_select_submit-' +
            params.flow_information.flow_step.toString().padStart(2, '0'),
          checkout_step: params.flow_information.flow_step,
          enh_action: 'checkout',
          page_name: pageType,
          page_section: 'house_building',
          page_category: 'portal_site',
          page_subcategory: 'bestellung',
          product_name: ['kontakt_hausbau'],
          product_formtype: ['katalog'],
          product_id: ['535'],
          product_quantity: [params.values.length.toString()]
        }
      }
      if (utagEvent) {
        if (!isProd) {
          console.log(
            '%cUTAG:',
            'background: #ffaaff; color: #fff; font-weight: bold; padding: 2px 3px; border-radius: 2px;',
            utagEvent
          )
        } else {
          window.utag?.view(utagEvent)
        }
      }
      // Push pageview event to AVIV GTM only on bauen.de
      const isBauenSite = /bauen/.test(window.__DOMAIN__)
      const pushPageviewEvent = () => {
        // Check if Usercentrics UI and the specific service is loaded and consent is given
        if (
          isBauenSite &&
          window.UC_UI?.getServicesBaseInfo().find(
            data => data.id === ga4UserCentricId
          ).consent.status
        ) {
          // GA4
          if (params.category === 'page') {
            let av_pageview_event = {
              event: 'page_view',
              av_url_path: window.location.pathname,
              av_platform: 'web',
              av_site: 'BUN',
              av_pagettitle: document.title,
              av_category: pageType,
              av_user: {
                cid: '2071035240.1710770466'
              }
            }
            if (!isProd) {
              console.log(
                '%cGTM:',
                'background: #55aa55; color: #fff; font-weight: bold; padding: 2px 3px; border-radius: 2px;',
                av_pageview_event
              )
            }
            window.dataLayer.push(av_pageview_event)
          }
          return true // Indicate that the push was successful
        }
        return false // Indicate that the push was not successful
      }
      // Function to check if Usercentrics is ready and execute the pushPageviewEvent function
      const checkUsercentricsAndPushEvent = () => {
        if (pushPageviewEvent()) {
          clearInterval(intervalId) // Clear the interval once the event is pushed
        }
      }
      // Set an interval to check if Usercentrics is ready every 500ms
      const intervalId = setInterval(checkUsercentricsAndPushEvent, 500)
    }
  }

  /* Initialise by going through the queue (if there's one), in case events fired before the library loaded */
  if (window['_wt'].q) {
    for (const event of window['_wt'].q) {
      const { t, ...rest } = event
      webTracking({
        local_timestamp: t,
        ...rest
      })
    }
  }

  /* Expose tracking function as a global variable _wt */
  window['_wt'] = webTracking
})()
